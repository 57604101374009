import {
  Title,
  PageContainer,
  Paragraph,
  DontHydrate,
  HeroImage,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { HeroBannerProps, Maybe } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { StaticPrice } from '@Components/Price/StaticPrice';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

interface TextComponentProps extends ClassNameProps {
  title: string;
  cheapestPricePence: Maybe<number>;
}

const TextComponent: React.FC<TextComponentProps> = ({ className, title, cheapestPricePence }) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      className={className}
      sx={{
        paddingTop: ['l', '4xl'],
        paddingBottom: [null, '4xl'],
      }}
    >
      <div
        sx={{
          width: ['100%', 400, 500],
          borderRadius: [null, '12'],
          padding: [null, 'xl'],
          backgroundColor: 'backgroundWhite',
        }}
      >
        {title && (
          <Title
            variant="small"
            as="h1"
          >
            {title}
          </Title>
        )}
        {cheapestPricePence && (
          <Paragraph variant="large">
            {`${t('priceQuoteUnit.from').toLowerCase()} `}
            <StaticPrice
              value={cheapestPricePence}
              suffix={t('pricesShownAre.perPerson')}
              sx={{
                fontWeight: 'bold',
              }}
            />
          </Paragraph>
        )}
      </div>
    </PageContainer>
  );
};

const BannerTextComponent: React.FC<TextComponentProps> = ({ title, cheapestPricePence }) => {
  const { t } = useTranslation();

  return (
    <div
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        padding: 'l',
        paddingBottom: ['xl', '4xl'],
        borderRadius: '12',
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 47%, transparent 100%)',
      }}
    >
      {title && (
        <Title
          variant="small"
          as="h1"
          sx={{
            color: 'white',
            filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
          }}
        >
          {title}
        </Title>
      )}
      {cheapestPricePence && (
        <Paragraph
          variant="large"
          sx={{
            color: 'white',
            filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
          }}
        >
          {`${t('priceQuoteUnit.from').toLowerCase()} `}
          <StaticPrice
            value={cheapestPricePence}
            suffix={t('pricesShownAre.perPerson')}
            sx={{
              fontWeight: 'bold',
            }}
          />
        </Paragraph>
      )}
    </div>
  );
};

type MainImageProps = Pick<HeroBannerProps, 'image' | 'title' | 'cheapestPricePence'>;

export const MainImage: React.FC<MainImageProps & ClassNameProps> = ({
  image,
  title,
  className,
  cheapestPricePence,
}) => {
  const constrainedHeroImage = useFeatureFlag('ConstrainedHeroImage');

  return (
    <DontHydrate>
      <div
        className={className}
        sx={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {constrainedHeroImage ? (
          <PageContainer
            sx={{
              position: 'relative',
            }}
          >
            <HeroImage
              image={image!}
              sx={{
                width: '100%',
                objectFit: 'cover',
                overflow: 'hidden',
                margin: '0 auto',
                borderRadius: '12',
              }}
              height={[150, 300, 380]}
              widths={[400, 810, 1280]}
              dpr={1}
            />
            <BannerTextComponent
              title={title}
              cheapestPricePence={cheapestPricePence}
              sx={{
                position: 'relative',
              }}
            />
          </PageContainer>
        ) : (
          <Fragment>
            <HeroImage
              image={image!}
              sx={{
                top: [null, 0],
                position: [null, 'absolute'],
                width: '100%',
                objectFit: 'cover',
                height: [null, '100%'],
              }}
              height={[150, 300, 420]}
            />
            <TextComponent
              title={title}
              cheapestPricePence={cheapestPricePence}
              sx={{
                position: 'relative',
              }}
            />
          </Fragment>
        )}
      </div>
    </DontHydrate>
  );
};
